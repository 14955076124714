import { SubscriptionPeriod } from '@/controllers/graphql/generated';

export const MONTHS_IN_YEAR = 12;

export const PAID_PLAN_BENEFITS: string[] = [
  'paywall.paid_plan_benefit_learning_path',
  'paywall.paid_plan_benefit_community',
  'paywall.paid_plan_benefit_auto_tech_checks',
  'paywall.paid_plan_benefit_ai_buddy',
  'paywall.paid_plan_benefit_gamification_features',
  'paywall.paid_plan_benefit_finish_course_get_offer',
];

export const dayPeriodMultiplicationCoefficients = {
  [SubscriptionPeriod.Day]: 1,
  [SubscriptionPeriod.Week]: 1 / 7,
  [SubscriptionPeriod.Month]: 1 / 30,
  [SubscriptionPeriod.Year]: 1 / 365,
  [SubscriptionPeriod.Lifetime]: Infinity,
};

export const weekPeriodMultiplicationCoefficients = {
  [SubscriptionPeriod.Day]: 7,
  [SubscriptionPeriod.Week]: 1,
  [SubscriptionPeriod.Month]: 1 / 4,
  [SubscriptionPeriod.Year]: 1 / 52,
  [SubscriptionPeriod.Lifetime]: Infinity,
};

export const monthPeriodMultiplicationCoefficients = {
  [SubscriptionPeriod.Day]: 30,
  [SubscriptionPeriod.Week]: 4,
  [SubscriptionPeriod.Month]: 1,
  [SubscriptionPeriod.Year]: 1 / 12,
  [SubscriptionPeriod.Lifetime]: Infinity,
};

export const yearPeriodMultiplicationCoefficients = {
  [SubscriptionPeriod.Day]: 365,
  [SubscriptionPeriod.Week]: 52,
  [SubscriptionPeriod.Month]: 12,
  [SubscriptionPeriod.Year]: 1,
  [SubscriptionPeriod.Lifetime]: Infinity,
};

export const lifetimePeriodMultiplicationCoefficients = {
  [SubscriptionPeriod.Day]: Infinity,
  [SubscriptionPeriod.Week]: Infinity,
  [SubscriptionPeriod.Month]: Infinity,
  [SubscriptionPeriod.Year]: Infinity,
  [SubscriptionPeriod.Lifetime]: Infinity,
};

export const periodMultiplicationCoefficients = {
  [SubscriptionPeriod.Day]: dayPeriodMultiplicationCoefficients,
  [SubscriptionPeriod.Week]: weekPeriodMultiplicationCoefficients,
  [SubscriptionPeriod.Month]: monthPeriodMultiplicationCoefficients,
  [SubscriptionPeriod.Year]: yearPeriodMultiplicationCoefficients,
  [SubscriptionPeriod.Lifetime]: lifetimePeriodMultiplicationCoefficients,
};
